<template>
  <router-view />
  <Loader />
</template>

<script>
import Loader from '@/components/Loader.vue';
export default {
  name: 'App',
  components: {
    Loader,
  },
};
</script>
<style lang="scss">
@import '~toastify-js/src/toastify.css';
body {
  margin: 0;
  font-family: Source Sans, sans-serif;
  color: #00246b;
}

a {
  text-decoration: none;
}
.mt-20 {
  margin-top: 20px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.cp {
  cursor: pointer;
}
.toastify {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border-radius: 4px;
  overflow: hidden;

  .toast-close {
    color: #ffffff00;
    position: relative;
    background: none;

    &::after {
      content: url('./assets/icons/close_toast_icon.svg');
      position: absolute;
      right: 4px;
    }
  }
}
.input-style {
  height: 40px;
  box-sizing: border-box;
  display: flex;
  padding: 8px 14px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #80bbff;
  background: #ffffff;

  caret-color: #80bbff;
  color: #00246b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.input-style:-webkit-autofill,
.input-style:-webkit-autofill:hover,
.input-style:-webkit-autofill:focus,
.input-style:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #80bbff inset !important;
  -webkit-text-fill-color: #80bbff !important;
}

.textarea {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #80bbff;
  background: #fff;
  resize: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  caret-color: #80bbff;
  box-sizing: border-box;
}

.input-style:disabled {
  background: rgb(245, 245, 245);
  color: #80bbff;
}

.input-style::placeholder {
  color: #80bbff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.input-style:focus,
textarea {
  outline: none;
}

.input-style:focus-visible {
  border: 1px solid #80bbff;
}
.label_valid {
  color: #e50914;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 4px;
}
.btn-blue {
  background: #0075ff;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 14px;
  box-sizing: border-box;
}
.btn-red {
  background: #ff0800;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 14px;
  box-sizing: border-box;
}
.btn-white {
  background: #ffffff;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  border: 2px solid #0075ff;
  color: #0075ff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 14px;
  box-sizing: border-box;
}
.label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #80bbff;
}
@font-face {
  font-family: 'Source Sans';
  src: local('Source Sans'),
    url(./assets/fonts/SourceSans3-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter.ttf) format('truetype');
}

.vs--searchable .vs__dropdown-toggle,
.vs--open .vs__dropdown-toggle {
  justify-content: center;
  align-items: center;
  padding: 6px 14px 8px 14px;
  //padding-top: 6px;

  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #80bbff;
  background: #ffffff;
  caret-color: #80bbff;
  color: #00246b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.vs__search,
.vs__search::placeholder,
.vs__search:focus::placeholder {
  color: #80bbff !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.4px !important;
}

.vs__actions svg {
  fill: #0075ff;
}

#community-admin-input {
  width: 100% !important;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  padding: 8px 14px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #80bbff;
  background: #ffffff;
  caret-color: #80bbff;
  color: #00246b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

#community-admin-input #community-admin-input:focus {
  outline: none;
}
</style>
