<template>
  <MainLayoutAdmin>
    <div class="home">Home</div>
  </MainLayoutAdmin>
</template>

<script>
import MainLayoutAdmin from '@/layouts/MainLayoutAdmin.vue';

export default {
  name: 'HomeView',
  components: {
    MainLayoutAdmin,
  },
};
</script>
